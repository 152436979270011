<template>
	<el-row :gutter="160">
		<el-col :span="2">
			<el-button type="primary" icon="el-icon-plus" @click="onClickAdd">添加新参数</el-button>
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="paramList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:4+'px'}" class="serverlist">
				<el-table-column prop="id" label="id" width="60"> </el-table-column>
				<el-table-column prop="name" label="参数名称"></el-table-column>
				<el-table-column prop="content" label="参数内容" show-overflow-tooltip></el-table-column>
				<el-table-column prop="desc" label="描述"></el-table-column>				
				<el-table-column prop="updatetime" label="更新时间"></el-table-column>
				<el-table-column prop="create_time" label="添加时间"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditParam(scope.row)" type="primary" icon="el-icon-edit" size="small">修改</el-button>
                        <el-button @click.native.prevent="onClickDelParam(scope.row)" type="danger" icon="el-icon-delete" size="small">删除</el-button>
                    </template>
                </el-table-column>
			</el-table>
		</el-col>
		<!-- ========弹框BEGIN====== -->
		<el-dialog :title="formTitle" :visible.sync="dialogForm" width="40%" :close-on-click-modal="false" center class="formparams">
			<el-form :model="form">
                <el-form-item label="参数名称" :label-width="formLabelWidth">
                    <el-input v-model="form.name" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item>
                <el-form-item label="描述" :label-width="formLabelWidth">
                    <el-input v-model="form.desc" autocomplete="off" @change="onFormEdited"></el-input>
                </el-form-item> 
                <el-form-item label="参数内容" :label-width="formLabelWidth">
                    <el-input v-model="form.content" autocomplete="off" type="textarea" :rows="8" @change="onFormEdited"></el-input>          
                </el-form-item>   
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onAddSystemParam" :disabled="noneChanged">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'

	export default {
		data() {
			return {
				paramList: [],
                formTitle: '',
				dialogForm: false,
                form: {
                    id: '',
                    name: '',
                    desc: '',
                    content: '',
                },
				noneChanged: true,
                formLabelWidth: '130px',
                tableHeight: document.documentElement.clientHeight-136,
			}
		},
		created: function() {
			this.refreshSystemParamList()
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
		},
		methods: {
			// 刷新列表
			refreshSystemParamList() {
				this.gameHttp(1010, {}).then((response) => {
					this.paramList = response.data.list
				})
			},
			// 弹出新增参数编辑框
			onClickAdd() {
                this.formTitle = '添加系统参数'
				this.dialogForm = true
                this.noneChanged = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
			},
            // 弹出修改参数编辑框
            onClickEditParam(row) {
                this.formTitle = '修改系统参数'
				this.dialogForm = true
                this.noneChanged = true 
                this.form = JSON.parse(JSON.stringify(row))
            },
            // 弹出删除确认框
            onClickDelParam(row) {
                this.$confirm('是否删除[ '+row.name+' ]系统参数?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(1012, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("删除成功")
                            this.refreshSystemParamList()
                        }
                    })
                }).catch(() => {})
            },            
            // 编辑框内容变化事件
            onFormEdited() {
                this.noneChanged = false
            },           
			// 添加系统配置
			onAddSystemParam() {
				if (0 == this.form.name.length) {
                    this.$message.error('参数名称不能为空'); return;
                }
				if (0 == this.form.content.length) {
                    this.$message.error('参数内容不能为空'); return;
                }                
                this.dialogForm = false
                // 请求操作
                this.gameHttp(1011, this.form).then((response) => {
                    response.data.errcode==0?this.$message.success(response.data.errmsg):this.$message.error(response.data.errmsg)
                    this.refreshSystemParamList()
                })
			},
			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	.serverlist {
		font-size: 12px;
	} 
</style>
